<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="content">
          <h3>{{ data.companiesName }}</h3>
          <h5>成立时间：{{ new Date(data.establishTime).toLocaleString() }}</h5>

          <div style="cursor: default" v-html="data.brief"></div>
        </div>
        <!--        <last-and-next v-on:toArticle="toArticle" :last="lastArticle" :next="nextArticle"></last-and-next>-->
      </div>
      <div slot="rightDiv">
        <card ref="card">
          <div slot="heard" class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
        </card>
        <div class="information">
          <h4><span></span>更多企业推荐</h4>
          <ul>
            <li v-for="item in moreCompanies" :key="item.id" @click="toMore(item)">
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
        <div class="information">
          <h4><span></span>企业信息</h4>
          <p><strong>企业名称：</strong>{{ data.companiesName }}</p>
          <p><strong>企业性质：</strong>{{ data.companiesType }}</p>
          <p><strong>所属行业：</strong>{{ data.industry }}</p>
          <p><strong>所在地址：</strong>{{ data.enrollAddr }}</p>
        </div>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";
//import lastAndNext from "@/components/public/lastAndNext";

export default {
  name: "businessDetails",
  components: {
    leftAndRight,
    card,
    // lastAndNext
  },
  data() {
    return {
      id: this.$route.query.id,
      data: {},
      moreCompanies:[],
      lastArticle: {
        name: '',
        id: null
      },
      nextArticle: {
        name: '',
        id: null
      }
    }
  },
  mounted() {
    this.getArticle()
  },
  methods: {
    getArticle() {
      let ths = this
      this.$http.get('/company/getOneBYid', {params: {id: this.$route.query.id}}).then((res) => {
        ths.data = res.data
      })
      this.$http.get("/companies/webSiteGet", {
        params: {
          title: this.title,
          current: 1,
          size: 5
        }
      }).then((res) => {
        this.moreCompanies = res.data.records
      })
    },
    toArticle(e) {
      this.id = e
      this.getArticle()
    },
    toMore(item) {
      this.$http.get('/company/getOneBYid', {params: {id: item.id}}).then((res) => {
        this.data = res.data
      })
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
}

h4 {
  margin-top: 0;
}

.content h3 {
  width: 100%;
  text-align: center;
}

.information {
  background: #f8f8f8;
  padding: 30px 18px;
  margin-bottom: 10px;
  border: 1px solid #dfdfdf;
}

.information p, h4 {
  text-indent: 0;
}

p {
  line-height: 24px;
  font-size: 14px;
  text-indent: 2em;
}

strong {
  color: red
}

.content h5 {
  width: 100%;
  color: #999999;
  text-align: center;
}

.search {
  width: 100%;
  height: 40px;
  position: relative;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

img {
  width: 100%;
}

span {
  display: inline-block;
  width: 3px;
  height: 12px;
  background: red;
  margin-right: 5px;
}
</style>
